@import "../../../shared/style/fonts";

.my-account {
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .account-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > p {
      @extend .font-H2;
      color: var(--color-primary);
      font-weight: 600;
      span {
        font-weight: 700;
        margin-inline-start: 5px;
      }
    }

    .points {
      display: flex;

      .win-points {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-inline-end: 25px;

        p {
          color: var(--color-on-surface-high-emphasis);
          font-weight: 700;
          @extend .font-H6;
          margin-bottom: 5px;
        }

        span {
          color: var(--color-primary);
          @extend .font-H5;
          font-weight: 600;
        }
      }

      .price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-primary);
        border-radius: 10px;
        padding: 5px 30px;

        p {
          color: var(--color-primary);
          font-weight: 600;
          @extend .font-H6;
          margin-bottom: 5px;
        }

        span {
          color: var(--color-primary);
          @extend .font-H5;
          font-weight: 600;
        }
      }
    }
  }

  .menu {
    display: flex;
    justify-content: space-between;

    .right {
      width: 19%;
      display: flex;
      flex-direction: column;

      .items {
        display: flex;
        flex-direction: column;
        background: var(--color-surface-light);
        box-shadow: 0px 3px 60px #c4c4c433;
        border-radius: 12px;
        // padding: 15px;

        .element {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin-bottom: 20px;
          padding: 12px 10px;

          &.active {
            background-color: rgba(122, 68, 197, 0.1);
          }

          > div {
            display: flex;
            align-items: center;

            div {
              padding: 8px;
              background-color: rgba(59, 10, 128, 0.1);
              border-radius: 50%;
              img {
                width: 20px;
                height: 17px;
              }
            }
            p {
              cursor: pointer;
              margin: 0;
              margin-inline-start: 10px;
              color: var(--color-primary);
              @extend .font-H4;
              font-weight: 700;
            }
          }

          img {
            width: 10px;
            height: 10px;
          }
        }
      }

      .logout {
        margin-top: 25px;
        color: var(--color-error);
        @extend .font-H4;
        font-weight: 600;

        p {
          cursor: pointer;
          width: fit-content;
        }
      }
    }

    .item-data {
      width: 79%;
      overflow: hidden;
    }

    .mobile-menu {
      display: none;
    }

    @media (max-width: 950px) {
      flex-direction: column;
      .right {
        display: none;
      }

      .mobile-menu {
        display: flex;
      }

      .item-data {
        width: 100%;
      }
    }
  }
}
