@import "../../../shared/style/fonts";

.search-result {
  width: 100%;
  background-color: var(--color-surface-light);

  .result {
    display: flex;
    padding: 10px 40px;
    @media (max-width: 975px) {
      flex-direction: column;
    }
  }

  .search-title {
    background: var(--color-primary);
    color: var(--color-surface-light);
    justify-content: center;
    align-items: center;
    padding: 11px 0;
    border-radius: 7px;
    width: 100%;
    margin-bottom: 20px;
    max-width: 150px;
    display: none;
    cursor: pointer;
    margin-top: 30px;

    h3 {
      color: var(--color-surface-light);
      width: fit-content;
      @extend .font-H3;
      margin: 0;
    }

    img {
      width: 15px;
      height: 15px;
      margin-inline-end: 10px;
    }

    @media (max-width: 975px) {
      display: flex;
    }
  }
}
