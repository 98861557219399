@import "../../../../shared/style/fonts";

.search-component {
  width: 100%;
  margin: 40px 0;
  display: flex;

  .content {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 10px;
  }

  .ant-tabs {
    border-radius: 10px;
    min-height: 200px;
    box-shadow: 0px 3px 12px var(--color-shadow-medium);
    direction: rtl;
    border: 0;
    width: 80%;

    @media (max-width: 600px) {
      width: 100%;
    }

    .ant-tabs-content-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
    }

    .ant-tabs-nav-operations {
      display: none;
      @media (max-width: 500px) {
        display: inline-block !important;
      }
    }

    .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-tab {
        @extend .font-H4;
        width: 25%;
        min-width: 100px;
        // margin-inline-end: 0.5% !important;
        border: 0;
        height: 50px;
        display: flex;
        justify-content: center;
        font-weight: 600;

        &.ant-tabs-tab-active .ant-tabs-tab-btn,
        .ant-tabs-tab-btn:hover {
          color: var(--color-primary);
        }
      }
    }
  }

  .consult {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-weight: 600;
      margin-bottom: 20px;
    }

    img {
      cursor: pointer;
      margin-inline-end: 10px;
    }
  }
}
