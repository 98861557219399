.booking-cart {
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .cart-header {
    p {
      color: var(--color-primary);
      font-weight: 600;
      font-size: 30px;
    }
  }

  .content {
    display: flex;
    justify-content: flex-start;

    .right {
      flex-basis: 50%;
      background: #ffffff;
      box-shadow: 0px 0px 40px #0000000d;
      border-radius: 12px;
      padding: 2%;
      margin-inline-end: 2%;

      .data {
        display: flex;
        justify-content: space-between;

        .name {
          display: flex;
          width: 50%;

          .image {
            border-radius: 50%;
            border: 0.25px solid #707070;
            width: 80px;
            height: 80px;
            margin-inline-end: 25px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          p {
            color: var(--color-primary);
            font-weight: 600;
            font-size: 20px;
            margin: 0;
            margin-bottom: 10px;

            &:last-of-type {
              color: #646d82;
            }
          }
        }

        .price {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 0;

          > p {
            color: #222;
            font-weight: 600;
            font-size: 18px;

            span {
              color: var(--color-primary);
              font-weight: 700;
              margin-inline-start: 5px;
            }
          }

          .address {
            display: flex;
            margin-top: 20px;
            color: #646d82;

            p {
              font-size: 15px;
            }

            a {
              display: flex;
              align-items: center;
              text-decoration: none;

              p {
                margin: 0;
                color: var(--color-on-surface-high-emphasis);
              }
            }
          }
        }

        &.services {
          flex-direction: column;

          h3 {
            color: var(--color-primary);
            font-weight: 600;
            margin-top: 25px;
            margin-bottom: 15px;
          }

          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 30px;

            .vaccine-name {
              display: flex;
              align-items: center;

              p {
                margin: 0;
                color: #222;
                font-weight: 600;
                font-size: 18px;
                margin-inline-start: 15px;
              }
            }

            .delete {
              display: flex;
              align-items: center;
              background: #ad9fbf;
              border-radius: 8px;
              padding: 10px 25px;
              cursor: pointer;

              p {
                margin: 0;
                margin-inline-start: 10px;
                color: #fff;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .left {
      flex-basis: 20%;
      background: #ffffff;
      box-shadow: 0px 0px 40px #0000000d;
      border-radius: 12px;
      padding: 1%;
      height: fit-content;

      > p {
        color: var(--color-primary);
        font-weight: 600;
        font-size: 22px;
      }

      .vaccine-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;

        p {
          color: #646d82;
          font-weight: 600;
        }
      }

      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: #646d82;
          font-weight: 600;

          &:last-of-type {
            color: var(--color-primary);
            font-weight: 600;
            font-size: 18px;
          }
        }
      }
    }
  }

  .cart-footer {
    margin-top: 30px;

    button {
      padding: 10px 40px;
      color: #fff;
      background-color: var(--color-primary);
      border-radius: 10px;
      font-weight: 600;
      font-size: 22px;
      border: 0;
      outline: 0;

      &:disabled {
        background-color: #eee;
      }
    }
  }
}
