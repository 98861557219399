@import "../../../../shared/style/fonts";

.ordersList-container {
  .element {
    box-shadow: 0px 0px 40px var(--color-shadow-primary);
    margin: 0;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 15px 10px;
    display: flex;
    width: 100%;

    @media (max-width: 900px) {
      width: 100%;
    }

    .doctor-image {
      padding-inline-end: 30px;
      flex-basis: 15%;

      .images {
        position: relative;
        width: fit-content;
        height: fit-content;
      }

      img {
        max-width: 200px;
        width: 140px;
        height: 140px;
        padding: 0;
        border-radius: 50%;
        object-fit: cover;

        &.medical-image {
          position: absolute;
          width: 37px;
          height: 37px;
          bottom: 0;
          left: 0px;
        }
      }

      @media (max-width: 800px) {
        display: flex;
        justify-content: center;

        img {
          width: 110px;
          height: 110px;

          &:last-of-type {
            width: 30px;
            height: 30px;
          }
        }
      }

      @media (max-width: 570px) {
        display: flex;
        justify-content: center;

        img {
          width: 100px;
          height: 100px;

          &:last-of-type {
            width: 25px;
            height: 25px;
          }
        }
      }
    }

    .doctor-name {
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
        width: fit-content;
        display: inline-block;
        color: var(--color-primary);
        @extend .font-H3;
      }
      img {
        width: 20px;
        height: 20px;
        padding: 0;
        margin-inline-start: 10px;
      }

      @media (max-width: 800px) {
        font-size: 14px;
      }
    }

    .doctor-description {
      @extend .font-H4;
      color: var(--color-on-surface-high-emphasis);
      margin: 0;
      margin: 15px 0;
      display: flex;
      align-items: center;

      img {
        padding: 0;
        width: 20px;
        height: 20px;
        margin-inline-end: 5px;
      }

      p {
        margin: 0;
        width: fit-content;
      }
    }

    .medical {
      margin-top: 15px;
      p {
        margin: 0;
        @extend .font-H5;
        color: var(--color-medium-dark);
        margin-bottom: 3px;
        font-weight: 600;

        &:last-of-type {
          @extend .font-H5;
          color: var(--color-on-surface-high-emphasis);
        }
      }
    }
  }

  .doctor-address {
    display: flex;
    flex-direction: column;
    flex-grow: 0.3;

    > div {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      img {
        width: 15px;
        height: 15px;
        margin-inline-end: 5px;
      }

      p {
        margin: 0;
        @extend .font-H5;
        color: var(--color-on-surface-high-emphasis);
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;

        p {
          margin: 0;
          color: var(--color-on-surface-high-emphasis);
        }
      }
    }

    .options-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .options {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.10000000149011612px solid var(--color-primary);
        background-color: var(--color-primary);
        color: #fff;
        padding: 10px 55px;
        border-radius: 10px;

        &.cancel {
          background-color: #ad9fbf;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-inline-end: 15px;

          &:last-of-type {
            margin: 0;
          }

          img {
            width: 20px;
            height: 20px;
          }

          p {
            margin-top: 5px;

            &.cancel {
              color: var(--color-error);
              @extend .font-H4;
            }
          }
        }
      }
    }
  }
}
