@import "../../shared/style/fonts";

.booking-doctor {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px;

  h2 {
    width: 80%;
    @extend .font-T3;
    color: var(--color-primary);
    font-weight: 600;
    margin-bottom: 30px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .doctor-content {
    width: 80%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .element {
    box-shadow: 0px 0px 40px var(--color-shadow-primary);
    margin: 0;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 15px 10px;
    display: flex;
    width: 79%;

    @media (max-width: 900px) {
      width: 100%;
    }

    .doctor-image {
      padding-inline-end: 30px;
      flex-basis: 15%;

      .images {
        position: relative;
        width: fit-content;
        height: fit-content;
      }

      img {
        max-width: 200px;
        width: 140px;
        height: 140px;
        padding: 0;
        border-radius: 50%;
        object-fit: cover;

        &:last-of-type {
          position: absolute;
          width: 37px;
          height: 37px;
          bottom: 0;
          left: 0px;
        }
      }

      @media (max-width: 800px) {
        display: flex;
        justify-content: center;

        img {
          width: 110px;
          height: 110px;

          &:last-of-type {
            width: 30px;
            height: 30px;
          }
        }
      }

      @media (max-width: 570px) {
        display: flex;
        justify-content: center;

        img {
          width: 100px;
          height: 100px;

          &:last-of-type {
            width: 25px;
            height: 25px;
          }
        }
      }
    }

    .doctor-name {
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
        width: fit-content;
        display: inline-block;
        color: var(--color-primary);
        @extend .font-H2;
      }
      img {
        width: 20px;
        height: 20px;
        padding: 0;
        margin-inline-start: 10px;
      }

      @media (max-width: 800px) {
        font-size: 14px;
      }
    }

    .doctor-description {
      @extend .font-H3;
      color: var(--color-on-surface-high-emphasis);
      margin: 0;
      margin: 15px 0;
      display: flex;
      align-items: center;

      img {
        padding: 0;
        width: 20px;
        height: 20px;
        margin-inline-end: 5px;
      }

      p {
        margin: 0;
        width: fit-content;
      }
    }

    .medical {
      margin-top: 15px;
      p {
        margin: 0;
        @extend .font-H4;
        color: var(--color-medium-dark);
        margin-bottom: 3px;
        font-weight: 600;

        &:last-of-type {
          @extend .font-H4;
          color: var(--color-on-surface-high-emphasis);
        }
      }
    }
  }

  .price {
    width: 20%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 40px var(--color-shadow-primary);
    border-radius: 12px;
    height: fit-content;
    @media (max-width: 900px) {
      width: 50%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    h3 {
      padding: 15px 10px;
      @extend .font-H3;
      color: var(--color-primary);
      font-weight: 600;
      margin: 0;
    }

    .top-price {
      padding: 15px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @extend .font-H5;
      color: var(--color-on-surface-high-emphasis);
      font-weight: 700;
      border-bottom: 1px solid #eee;
      p {
        margin: 0;
      }
    }

    .bottom-price {
      padding: 15px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @extend .font-H5;
      color: var(--color-on-surface-high-emphasis);
      font-weight: 700;
      p {
        margin: 0;
        &:last-of-type {
          color: var(--color-primary);
        }
      }
    }
  }

  .doctor-address {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    > div {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      img {
        width: 20px;
        height: 20px;
        margin-inline-end: 10px;
      }

      p {
        @extend .font-H4;
        margin: 0;
        color: #868080;
        font-weight: 600;
      }
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;

      p {
        margin: 0;
        color: var(--color-on-surface-high-emphasis);
      }
    }
  }

  .booking-steps {
    width: 80%;
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;

    @media (max-width: 600px) {
      width: 100%;
    }

    .steps {
      display: flex;
      flex-direction: column;
      margin-inline-end: 15px;
      align-items: center;
      padding-top: 8px;

      .circle {
        width: 10px;
        height: 10px;
        background-color: var(--color-primary);
        border-radius: 50%;
      }

      .line {
        width: 2px;
        height: 50px;
        background-color: #f3f3f3;

        &:nth-of-type(2) {
          height: 198px !important;
        }

        &:nth-of-type(4) {
          height: 127px !important;
        }

        &:nth-of-type(6) {
          height: 290px !important;
        }

        @media (max-width: 1279px) {
          &:nth-of-type(4) {
            height: 118px !important;
          }
        }

        @media (max-width: 600px) {
          &:nth-of-type(2) {
            height: 167px !important;
          }

          &:nth-of-type(4) {
            height: 120px !important;
          }

          &:nth-of-type(6) {
            height: 500px !important;
          }
        }

        @media (max-width: 601px) {
          &:nth-of-type(2) {
            height: 168px !important;
          }
        }

        @media (max-width: 420px) {
          // &:nth-of-type(2) {
          //   height: 153px !important;
          // }

          &:nth-of-type(4) {
            height: 167px !important;
          }
        }

        @media (max-width: 280px) {
          &:nth-of-type(2) {
            height: 205px !important;
          }

          // &:nth-of-type(4) {
          //   height: 205px !important;
          // }
        }
      }
    }

    .booking-data {
      .step-title {
        color: var(--color-primary-dark);
        @extend .font-H3;
        font-weight: 600;
      }

      .step-one {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        > span {
          display: flex;
          margin-bottom: 50px;
        }

        .first-row {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        .field {
          width: 49%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          @media (max-width: 600px) {
            width: 100%;
          }

          label {
            color: #6d6868;
            @extend .font-H5;
            font-weight: 700;
            margin-bottom: 7px;
            margin-top: 10px;
            display: flex;

            span {
              color: #f00;
              margin-inline-start: 5px;
              font-weight: 700;
            }
          }

          input {
            border: 0;
            outline: 0;
            background-color: var(--color-primary-light);
            border-radius: 7px;
            padding: 10px;
          }
        }
      }

      .step-two {
        display: flex;
        flex-direction: column;
        margin-bottom: 35px;
        > p:last-of-type {
          @extend .font-H3;
          font-weight: 600;
        }

        .ant-radio-wrapper {
          margin-bottom: 0;
        }
      }

      .step-three {
        display: flex;
        flex-direction: column;

        .payment {
          display: flex;
          img {
            width: 25px;
            height: 25px;
            margin-inline-end: 10px;
          }
        }

        .first-row,
        .second-row,
        .thrid-row,
        .fourth-row {
          display: flex;
          justify-content: space-between;
          //   width: 50%;
          margin-top: 20px;
          flex-wrap: wrap;

          @media (max-width: 600px) {
            width: 100%;
          }

          .field {
            width: 49%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @media (max-width: 600px) {
              width: 100%;
            }

            label {
              color: #6d6868;
              @extend .font-H5;
              font-weight: 700;
              margin-bottom: 7px;
              margin-top: 10px;
              display: flex;

              span {
                color: #f00;
                margin-inline-start: 5px;
                font-weight: 700;
              }
            }

            input {
              border: 0;
              outline: 0;
              background-color: var(--color-primary-light);
              border-radius: 7px;
              padding: 10px;
            }
          }
        }

        .first-row {
          .field {
            width: 100%;
          }
        }

        .second-row {
          .field {
            width: 49%;
            @media (max-width: 600px) {
              width: 100%;
            }
          }
        }

        .thrid-row {
          .css-b62m3t-container {
            border-radius: 5px;
          }

          .css-1s2u09g-control {
            background-color: var(--color-primary-light);
            border: 0;
            height: 44px;
          }
        }
      }

      .step-four {
        display: flex;
        margin-top: 30px;

        button {
          background-color: var(--color-primary);
          color: var(--color-surface-light);
          @extend .font-H4;
          font-weight: 700;
          padding: 10px 25px;
          border-radius: 10px;
          border: 0;
          outline: 0;
        }

        @media (max-width: 600px) {
          justify-content: center;
        }
      }
    }
  }

  .ant-radio-checked .ant-radio-inner::after {
    background-color: var(--color-primary);
  }

  .ant-radio-wrapper {
    margin: 0;
    margin-bottom: 15px;
    margin-inline-end: 20px;
    @extend .font-H4;
    font-weight: 600;
    color: var(--color-primary-dark);
  }

  .ant-radio-checked .ant-radio-inner {
    border: 1px solid var(--color-primary);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }

  .ant-checkbox-wrapper {
    @extend .font-H3;
    font-weight: 600;
    color: var(--color-primary-dark);
  }
}

.success-modal {
  .ant-modal-body {
    padding: 0;
  }

  .booking-success {
    .image {
      background-color: rgba(80, 25, 161, 0.3);
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        background-color: var(--color-primary);
        border-radius: 50%;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .success-data {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      @extend .font-H1;
      color: #0b3f69;
      font-weight: 700;
    }

    p {
      @extend .font-H4;
      font-weight: 600;
      color: var(--color-border-medium);
    }

    button {
      border-radius: 10px;
      padding: 10px 20px;
      background-color: var(--color-primary);
      border: 0;
      outline: 0;

      a {
        @extend .font-H5;
        color: var(--color-surface-light);
        text-decoration: none;
        font-weight: 600;
      }
    }

    > a {
      color: var(--color-primary);
      @extend .font-H4;
      text-decoration: none;
      font-weight: 600;
      margin: 15px;
    }
  }

  .backdrop {
    z-index: 1000;
    color: var(--color-surface-light);
  }
}
