@import "../../../../shared/style/fonts";

.medical-result {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 570px) {
    justify-content: center;
  }

  .header-title {
    justify-content: space-between;
    align-items: center;

    p {
      @extend .font-H3;
      color: var(--color-primary-dark);
      font-weight: bold;
    }
  }

  .sort {
    display: flex;
    align-items: center;

    label {
      margin-inline-end: 10px;
    }

    select {
      max-width: 200px;
    }
  }

  .price {
    align-items: center;
    margin-top: 15px;
    display: flex;

    img {
      width: 20px;
      padding: 0;
      height: 20px;
      margin-inline-end: 10px;
    }

    p {
      @extend .font-H4;
      width: fit-content;
      margin: 0;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;

      p {
        margin: 0;
        color: var(--color-on-surface-high-emphasis);
      }
    }
  }

  .doctor-data {
    flex-basis: 40%;
    flex: 1;

    @media (max-width: 570px) {
      flex-basis: 100%;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
    }
  }

  .doctor-image {
    padding-inline-end: 10px;
    flex-basis: 15%;

    .images {
      position: relative;
      width: fit-content;
      height: fit-content;
    }

    img {
      max-width: 200px;
      width: 140px;
      height: 140px;
      padding: 0;
      border-radius: 50%;
      object-fit: cover;

      &.medical-center-image {
        position: absolute;
        width: 37px;
        height: 37px;
        bottom: 0;
        left: 0px;
      }
    }

    @media (max-width: 800px) {
      display: flex;
      justify-content: center;

      img {
        width: 110px;
        height: 110px;

        &.medical-center-image {
          width: 30px;
          height: 30px;
        }
      }
    }

    @media (max-width: 570px) {
      display: flex;
      justify-content: center;

      img {
        width: 100px;
        height: 100px;

        &.medical-center-image {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .doctor-name {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      width: fit-content;
      display: inline-block;
      color: var(--color-primary);
      @extend .font-H2;
    }
    img {
      width: 20px;
      height: 20px;
      padding: 0;
      margin-inline-start: 10px;
    }

    @media (max-width: 800px) {
      font-size: 14px;
    }
  }

  .element {
    box-shadow: 0px 0px 40px var(--color-shadow-primary);
    margin: 0;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    padding: 15px 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .doctor-description {
    @extend .font-H3;
    color: var(--color-on-surface-high-emphasis);
    margin: 0;
    margin: 15px 0;
    display: flex;
    align-items: center;

    img {
      padding: 0;
      width: 20px;
      height: 20px;
      margin-inline-end: 5px;
    }

    p {
      margin: 0;
      width: fit-content;
    }
  }

  .medical {
    margin-top: 15px;
    p {
      margin: 0;
      @extend .font-H4;
      color: var(--color-medium-dark);
      margin-bottom: 3px;
      font-weight: 600;

      &:last-of-type {
        @extend .font-H4;
        color: var(--color-on-surface-high-emphasis);
      }
    }
  }

  .doctor-rate {
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 720px) {
      flex-basis: 100%;
      margin-top: 10px;
    }

    .rate {
      justify-content: flex-end;
    }
    .ant-rate {
      width: fit-content;
    }
  }

  .booking {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      @extend .font-H4;
      background-color: var(--color-primary);
      color: var(--color-surface-light);
      font-weight: bold;
      text-decoration: none;
      text-align: center;
      padding: 10px 0;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @media (max-width: 720px) {
        width: 50%;
      }
    }
  }

  .more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 34px 0;

    button {
      @extend .font-H4;
      border: 0;
      outline: 0;
      color: var(--color-on-primary-high-emphasis);
      background-color: var(--color-primary);
      border-radius: 10px;
      padding: 10px 20px;
    }
  }
}
