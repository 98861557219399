.public-info {
  .info-content {
    display: flex;
    flex-direction: column;
    position: relative;

    .edit {
      color: var(--color-primary);
      background-color: transparent;
      border: 0;
      outline: 0;
      position: absolute;
      left: 0;
      top: -50px;
      font-weight: 600;
    }

    .field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .data {
        display: flex;
        align-items: center;

        h3 {
          color: var(--color-primary);
          font-size: 18px;
          margin: 0;
        }

        p {
          margin: 0;
          font-size: 17px;
          margin-inline-start: 10px;
          color: #646d82;
        }
      }
    }

    .sense {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      h3 {
        color: var(--color-primary);
        font-size: 18px;
        margin: 0;
        margin-top: 10px;
      }

      p {
        margin-top: 10px;
        color: #646d82;
      }
    }

    .medicine {
      display: flex;
      flex-direction: column;

      h3 {
        color: var(--color-primary);
        font-size: 18px;
        margin: 0;
        margin-top: 10px;
      }

      p {
        margin-top: 10px;
        color: #646d82;
      }
    }
  }

  .info-form {
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }

    .ant-select {
      padding: 6px 10px;
      border-radius: 5px;
      background-color: #f5f5f5;
      border: 0.25px solid #6f6f6f;

      .ant-select-selector {
        border: 0;
        background-color: #f5f5f5;
      }
    }

    .fields {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .field {
        width: 25%;
        position: relative;

        input {
          padding: 10px;
          border-radius: 5px;
          background-color: #f5f5f5;
          border: 0.25px solid #6f6f6f;
        }

        &.sense,
        &.medicine {
          width: 100%;

          textarea {
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            background-color: #f5f5f5;
            border: 0.25px solid #6f6f6f;
            min-height: 90px;
          }
        }
      }

      .suffix {
        position: absolute;
        left: 10px;
        top: 50px;
      }
    }

    button {
      background-color: var(--color-primary);
      padding: 20px 30px;
      border-radius: 5px;
      border: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-form-item-label {
      text-align: right;
      font-size: 18px;
      margin-bottom: 5px;
      color: #222222;
      font-weight: 600;
    }
  }
}
