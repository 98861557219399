.terms-container {
  padding: 20px;

  h3 {
    text-align: center;
    margin-bottom: 30px;
    color: var(--color-primary);
    font-size: 2rem;
  }

  p {
    text-align: left;
    direction: ltr;

    h5 {
      margin: 15px 0;
      font-weight: 600;
    }

    span {
      display: inline-block;
      margin-bottom: 7px;
    }
  }
}
