@import "../../../../shared/style/fonts";

.contact-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 70px;

  @media (max-width: 600px) {
    margin-bottom: 40px;
  }

  .contact-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80%;

    @media (max-width: 600px) {
      width: 100%;
    }

    h4 {
      @extend .font-H2;
      color: var(--color-primary);
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
    }

    h2 {
      @extend .font-T3;
      color: var(--color-on-surface-high-emphasis);
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
    }
    span {
      width: 65px;
      padding: 3px 0;
      background: var(--color-primary);
      border-radius: 20px;
    }

    p {
      color: #9d9d9d;
      width: 40%;
      margin-top: 15px;
      margin-bottom: 30px;
      text-align: center;

      @media (max-width: 900px) {
        width: 70%;
      }

      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }

  .contact-data {
    display: flex;
    justify-content: space-between;
    width: 80%;
    direction: rtl;
    flex-wrap: wrap;

    .right {
      width: 29%;
      background-color: var(--color-primary-light);
      border-radius: 10px;
      padding: 40px 20px;
      opacity: 0.6;

      .element {
        display: flex;
        flex-direction: column;
        direction: rtl;

        div {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            margin: 0;
            padding: 0;
            margin-inline-end: 10px;
          }

          h4 {
            @extend .font-H3;
            font-weight: 700;
            margin: 0;
          }
        }

        p {
          margin: 0;
          @extend .font-H4;
          margin-bottom: 20px;
          margin-top: 5px;
          //   &:not(:last-child) {
          //   }
        }
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      width: 69%;

      input,
      textarea {
        width: 100%;
        background-color: var(--color-primary-light);
        border: 0;
        outline: 0;
        border-radius: 10px;
        padding: 10px 10px;
        // margin-bottom: 15px;
        @extend .font-H4;
        resize: none;
      }

      ::placeholder {
        color: var(--color-placeholder);
        @extend .font-H4;
        font-weight: 600;
      }

      button {
        @extend .font-H4;
        width: fit-content;
        color: var(--color-surface-light);
        background-color: var(--color-primary);
        border: 0;
        outline: 0;
        border-radius: 10px;
        padding: 10px 15px;

        @media (max-width: 600px) {
          font-size: 11px !important;
        }
      }
    }

    @media (max-width: 910px) {
      .left,
      .right {
        width: 100%;
        margin-top: 10px;
      }

      .left {
        justify-content: center;
        align-items: center;
      }
    }

    @media (max-width: 600px) {
      width: 100%;

      button {
        font-size: 10px !important;
      }
    }
  }

  .error {
    color: red;
    display: inline-block;
    margin: 5px 0 15px;
    font-size: 12px;
    transition: 0.5s;
  }
}
