@import "../../../../shared/style/fonts";

.our-partner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin-top: 30px;

  .partner-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80%;

    @media (max-width: 600px) {
      width: 100%;
    }

    h4 {
      @extend .font-H2;
      color: var(--color-primary);
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
    }

    h2 {
      @extend .font-T3;
      color: var(--color-on-surface-high-emphasis);
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
    }
    span {
      width: 65px;
      padding: 3px 0;
      background: var(--color-primary);
      border-radius: 20px;
    }
  }

  .partner-data {
    display: flex;
    width: 80%;
    margin-top: 60px;
    margin-bottom: 40px;
    direction: rtl;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 600px) {
      width: 100%;
    }

    img {
      width: 120px;
      height: 100px;
      filter: brightness(40%);
      margin-top: 10px;

      &:hover {
        filter: brightness(100%);
        cursor: pointer;
      }
    }
  }
}
