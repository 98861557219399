.view-medical {
  width: 100%;
  justify-content: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 500px) {
    padding: 15px;
  }
}
