@import "../../../../shared/style/fonts";

.login {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;

  @media (max-width: 500px) {
    padding: 15px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 38%;
    min-width: 300px;
    max-width: 406px;

    @media (max-width: 450px) {
      min-width: unset;
      width: 100%;
    }

    h3 {
      @extend .font-T4;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      color: var(--color-primary);
      margin-bottom: 20px;
      font-weight: 600;
    }

    .form {
      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        @extend .font-H4;
        color: var(--color-primary-dark);
        margin-bottom: 10px;
      }

      input {
        @extend .font-H3;
        width: 100%;
        padding: 10px;
        background: var(--color-primary-light);
        border: 0.25px solid var(--color-border-medium);
        border-radius: 12px;
        outline: none;
        color: var(--color-medium-dark);
        margin-bottom: 5px;
        direction: ltr;
        text-align: right;

        &::placeholder {
          font-size: 14px;
          color: var(--color-placeholder);
        }
      }

      .password {
        position: relative;
        display: flex;
        align-items: center;

        span {
          position: absolute;
          left: 3%;
          top: 16px;
          color: var(--color-primary);
          font-size: 10px;
          font-weight: bold;
          cursor: pointer;
        }
      }

      a {
        @extend .font-H4;
        color: var(--color-primary);
        cursor: pointer;
        margin-bottom: 30px;
        text-decoration: none;
        width: fit-content;
        font-weight: 600;

        span {
          color: var(--color-primary-dark);
          cursor: text;
          margin-top: 12px;
          display: inline-block;
        }
      }

      button {
        @extend .font-H4;
        width: 120px;
        height: 45px;
        background: var(--color-primary);
        border-radius: 9px;
        color: var(--color-surface-light);
        outline: none;
        border: 0;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  p {
    @extend .font-H6;
    color: var(--color-primary);
    margin: 0;
    font-weight: 600;
  }

  .error {
    color: var(--color-error);
    font-size: 10px;
    margin-bottom: 10px;
  }
  .backdrop {
    z-index: 1000;
    color: var(--color-surface-light);
  }
}

.forget-password-text {
  color: var(--color-primary);
  margin-bottom: 10px;
  cursor: pointer;
}

.forget-password {
  .ant-form-item-label {
    text-align: right !important;
  }

  .ant-input-affix-wrapper {
    border-radius: 7px;
    padding: 10px;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  input {
    padding: 7px;
    border-radius: 7px;
    direction: ltr;
    text-align: right;
  }

  h3 {
    color: var(--color-primary);
    @extend .font-H2;
    margin: 30px 0 20px;
    text-align: center;
    font-weight: 600;
  }

  label {
    @extend .font-H4;
  }

  button {
    background-color: var(--color-primary);
    color: var(--color-surface-light);
    border-radius: 5px;
    border: 0;
    outline: 0;
    width: 120px;
    text-align: center;
    padding: 10px 0;
  }
}
