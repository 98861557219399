.modal-container {
  &.content {
    display: flex;
    flex-direction: column;
    direction: rtl;
    padding: 20px 15px;

    h3 {
      font-size: 20px;
      color: var(--color-primary);
    }

    p {
      font-size: 14px;
      color: var(--color-border-medium);

      #mobile {
        display: inline-block;
        direction: ltr;
      }
    }

    @media (max-width: 600px) {
      h3 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }
    }

    .inputs {
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        width: 50px;
        height: 45px;
        background-color: var(--color-primary-light);
        border: 0.25px solid var(--color-border-medium);
        border-radius: 12px;
        margin-inline-end: 2.5%;
        outline: 0;
        text-align: center;
        font-size: 18px;
        color: var(--color-primary);
        padding: 10px;

        &:focus {
          background-color: var(--color-surface-light);
          border: 0.25px solid #5a3391;
        }
      }
    }

    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;

      button {
        background: var(--color-primary);
        border-radius: 10px;
        text-align: center;
        font-size: 18px;
        color: var(--color-surface-light);
        outline: 0;
        border: 0;
        margin-inline-end: 20px;
        cursor: pointer;
        padding: 10px 15px;

        @media (max-width: 600px) {
          font-size: 16px;
        }
      }

      p {
        font-size: 20px;
        color: var(--color-primary-dark);
      }
    }
  }
  .anticon {
    width: 43px;
    height: 43px;
    color: #5a3391;
  }
}

.ant-modal-content {
  border-radius: 12px;
}
