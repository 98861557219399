.notifications-popover {
    width: 250px;
    padding-left: 0;
    padding-right: 0;

    .ant-popover-inner-content {
        padding-left: 0;
        padding-right: 0;

        .notification-menu-wrapper {
            max-height: 400px;
            overflow: auto;

            .notifications-menu {
                border: none;

                .ant-menu-item {
                    height: auto;
                    white-space: inherit;
                    line-height: 1.2;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;

                    &.ant-menu-item-selected {
                        background-color: unset;
                    }

                    &.unread-notification {
                        background-color: rgba(#3B0A80,0.05);
                    }

                    &:hover {
                        background-color: rgba(#3B0A80,0.1);
                    }

                    &:active, &:focus {
                        background-color: rgba(#3B0A80,0.15);
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(#3B0A80,0.2);
                        margin-bottom: 0.5rem;
                        padding-bottom: 0.5rem;
                    }

                    .notification-item {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
