.who-are-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 2.5rem;
    color: var(--color-primary);
    margin-bottom: 30px;
  }

  li {
    margin-bottom: 15px;
    color: rgb(106, 106, 106);
  }
}
