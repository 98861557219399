@import "../../shared/style/fonts";

.booking-lab {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;

  h2 {
    width: 100%;
    @extend .font-T3;
    color: var(--color-primary);
    font-weight: 600;
    margin-bottom: 30px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .doctor-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .right {
      flex-basis: 50%;
      background: #ffffff;
      box-shadow: 0px 0px 40px #0000000d;
      border-radius: 12px;
      padding: 2%;
      margin-inline-end: 2%;

      .data {
        display: flex;
        justify-content: space-between;

        .name {
          display: flex;
          width: 50%;

          .image {
            border-radius: 50%;
            border: 0.25px solid #707070;
            width: 80px;
            height: 80px;
            margin-inline-end: 25px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          p {
            color: var(--color-primary);
            font-weight: 600;
            font-size: 20px;
            margin: 0;
            margin-bottom: 10px;

            &:last-of-type {
              color: #646d82;
            }
          }
        }

        .price {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 0;

          > p {
            color: #222;
            font-weight: 600;
            font-size: 18px;

            span {
              color: var(--color-primary);
              font-weight: 700;
              margin-inline-start: 5px;
            }
          }

          .address {
            display: flex;
            margin-top: 20px;
            color: #646d82;

            p {
              font-size: 15px;
            }

            a {
              display: flex;
              align-items: center;
              text-decoration: none;

              p {
                margin: 0;
                color: var(--color-on-surface-high-emphasis);
              }
            }
          }
        }

        &.services {
          flex-direction: column;

          h3 {
            color: var(--color-primary);
            font-weight: 600;
            margin-top: 25px;
            margin-bottom: 15px;
          }

          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 30px;

            .vaccine-name {
              display: flex;
              align-items: center;

              p {
                margin: 0;
                color: #222;
                font-weight: 600;
                font-size: 18px;
                margin-inline-start: 15px;
              }
            }

            .delete {
              display: flex;
              align-items: center;
              background: #ad9fbf;
              border-radius: 8px;
              padding: 10px 25px;
              cursor: pointer;

              p {
                margin: 0;
                margin-inline-start: 10px;
                color: #fff;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .left {
      flex-basis: 20%;
      background: #ffffff;
      box-shadow: 0px 0px 40px #0000000d;
      border-radius: 12px;
      padding: 1%;
      height: fit-content;

      > p {
        color: var(--color-primary);
        font-weight: 600;
        font-size: 22px;
      }

      .vaccine-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;

        p {
          color: #646d82;
          font-weight: 600;
        }
      }

      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: #646d82;
          font-weight: 600;

          &:last-of-type {
            color: var(--color-primary);
            font-weight: 600;
            font-size: 18px;
          }
        }
      }
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .booking-steps {
    width: 80%;
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;

    .date {
      display: flex;
      align-items: center;

      .ant-picker {
        border-radius: 5px;
        padding: 8px;
      }

      label {
        color: #222222;
        margin-bottom: 10px;
      }

      .month {
        display: flex;
        flex-direction: column;
        width: 250px;
        margin-inline-end: 20px;
      }

      .time {
        display: flex;
        flex-direction: column;
      }
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    .steps {
      display: flex;
      flex-direction: column;
      margin-inline-end: 15px;
      align-items: center;
      padding-top: 8px;

      .circle {
        width: 10px;
        height: 10px;
        background-color: var(--color-primary);
        border-radius: 50%;
      }

      .line {
        width: 2px;
        height: 50px;
        background-color: #f3f3f3;

        &:nth-of-type(2) {
          height: 137px !important;
        }

        &:nth-of-type(4) {
          height: 137px !important;
        }

        &:nth-of-type(6) {
          height: 137px !important;
        }

        @media (max-width: 1279px) {
          &:nth-of-type(4) {
            height: 118px !important;
          }
        }

        @media (max-width: 600px) {
          &:nth-of-type(2) {
            height: 167px !important;
          }

          &:nth-of-type(4) {
            height: 120px !important;
          }

          &:nth-of-type(6) {
            height: 500px !important;
          }
        }

        @media (max-width: 601px) {
          &:nth-of-type(2) {
            height: 168px !important;
          }
        }

        @media (max-width: 420px) {
          // &:nth-of-type(2) {
          //   height: 153px !important;
          // }

          &:nth-of-type(4) {
            height: 167px !important;
          }
        }

        @media (max-width: 280px) {
          &:nth-of-type(2) {
            height: 205px !important;
          }

          // &:nth-of-type(4) {
          //   height: 205px !important;
          // }
        }
      }
    }

    .booking-data {
      .step-title {
        color: var(--color-primary-dark);
        @extend .font-H3;
        font-weight: 600;
      }

      .step-one {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        > span {
          display: flex;
          margin-bottom: 50px;
        }

        .first-row {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        .field {
          width: 49%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          @media (max-width: 600px) {
            width: 100%;
          }

          label {
            color: #6d6868;
            @extend .font-H5;
            font-weight: 700;
            margin-bottom: 7px;
            margin-top: 10px;
            display: flex;

            span {
              color: #f00;
              margin-inline-start: 5px;
              font-weight: 700;
            }
          }

          input {
            border: 0;
            outline: 0;
            background-color: var(--color-primary-light);
            border-radius: 7px;
            padding: 10px;
          }
        }
      }

      .step-two {
        display: flex;
        flex-direction: column;
        margin-bottom: 35px;
        > p:last-of-type {
          @extend .font-H3;
          font-weight: 600;
        }

        .ant-radio-wrapper {
          margin-bottom: 0;
        }
      }

      .step-three {
        display: flex;
        flex-direction: column;

        .payment {
          display: flex;
          img {
            width: 25px;
            height: 25px;
            margin-inline-end: 10px;
          }
        }

        .first-row,
        .second-row,
        .thrid-row,
        .fourth-row {
          display: flex;
          justify-content: space-between;
          //   width: 50%;
          margin-top: 20px;
          flex-wrap: wrap;

          @media (max-width: 600px) {
            width: 100%;
          }

          .ant-select-selector {
            border: 0;
            outline: 0;
            background-color: var(--color-primary-light);
            border-radius: 7px;
            padding: 10px;
            height: 44px;
          }

          .field {
            width: 49%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @media (max-width: 600px) {
              width: 100%;
            }

            label {
              color: #6d6868;
              @extend .font-H5;
              font-weight: 700;
              margin-bottom: 7px;
              margin-top: 10px;
              display: flex;

              span {
                color: #f00;
                margin-inline-start: 5px;
                font-weight: 700;
              }
            }

            input {
              border: 0;
              outline: 0;
              background-color: var(--color-primary-light);
              border-radius: 7px;
              padding: 10px;
            }
          }
        }

        .first-row {
          .field {
            width: 100%;
          }
        }

        .second-row {
          .field {
            width: 49%;
            @media (max-width: 600px) {
              width: 100%;
            }
          }
        }

        .thrid-row {
          .css-b62m3t-container {
            border-radius: 5px;
          }

          .css-1s2u09g-control {
            background-color: var(--color-primary-light);
            border: 0;
            height: 44px;
          }
        }
      }

      .step-four {
        display: flex;
        margin-top: 30px;

        button {
          background-color: var(--color-primary);
          color: var(--color-surface-light);
          @extend .font-H4;
          font-weight: 700;
          padding: 10px 25px;
          border-radius: 10px;
          border: 0;
          outline: 0;
        }

        @media (max-width: 600px) {
          justify-content: center;
        }
      }
    }
  }

  .ant-radio-checked .ant-radio-inner::after {
    background-color: var(--color-primary);
  }

  .ant-radio-wrapper {
    margin: 0;
    margin-bottom: 15px;
    margin-inline-end: 20px;
    @extend .font-H4;
    font-weight: 600;
    color: var(--color-primary-dark);
  }

  .ant-radio-checked .ant-radio-inner {
    border: 1px solid var(--color-primary);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }

  .ant-checkbox-wrapper {
    @extend .font-H3;
    font-weight: 600;
    color: var(--color-primary-dark);
  }
}

.success-modal {
  .ant-modal-body {
    padding: 0;
  }

  .booking-success {
    .image {
      background-color: rgba(80, 25, 161, 0.3);
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        background-color: var(--color-primary);
        border-radius: 50%;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .success-data {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      @extend .font-H1;
      color: #0b3f69;
      font-weight: 700;
    }

    p {
      @extend .font-H4;
      font-weight: 600;
      color: var(--color-border-medium);
    }

    button {
      border-radius: 10px;
      padding: 10px 20px;
      background-color: var(--color-primary);
      border: 0;
      outline: 0;

      a {
        @extend .font-H5;
        color: var(--color-surface-light);
        text-decoration: none;
        font-weight: 600;
      }
    }

    > a {
      color: var(--color-primary);
      @extend .font-H4;
      text-decoration: none;
      font-weight: 600;
      margin: 15px;
    }
  }

  .backdrop {
    z-index: 1000;
    color: var(--color-surface-light);
  }
}
