@import "../../../shared/style/fonts";

.home {
  width: 100%;
  display: flex;
  direction: ltr;
  flex-direction: column;

  .background-home {
    padding: 10px;
    width: 100%;
    display: flex;
    direction: ltr;

    @media (max-width: 500px) {
      padding: 15px;
    }

    .left,
    .right {
      flex-basis: 50%;
      overflow: hidden;
      height: 500px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      img {
        width: 90%;
        height: 100%;
      }
    }

    .left {
      background-image: url("/assets/images/landing-page.png");
    }

    .right {
      background-image: url("/assets/images/main-shape.svg");
      display: flex;
      align-items: center;
      justify-content: center;

      .content {
        display: flex;
        flex-direction: column;
        width: fit-content;

        h3 {
          @extend .font-T1;
          color: var(--color-on-surface-high-emphasis);
          direction: rtl;
          font-weight: bold;
        }

        p {
          @extend .font-H4;
          color: #9899a5;
        }
      }
    }
  }

  @media (max-width: 900px) {
    .background-home {
      .left {
        display: none;
      }

      .right {
        flex-basis: 100% !important;
        height: 300px !important;
      }
    }
  }
}
