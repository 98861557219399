@import "../../../../shared/style/fonts";

.pharmacy-container {
  width: 100%;
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  h3 {
    @extend .font-T3;
    color: var(--color-on-surface-high-emphasis);
    font-weight: 600;
    width: 80%;
    margin-bottom: 25px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .pharmacy-data {
    width: 80%;
    background-color: var(--color-primary);
    color: var(--color-on-primary-high-emphasis);
    position: relative;
    height: 220px;
    border-radius: 10px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    justify-content: space-around;
    direction: rtl;

    h4 {
      color: var(--color-on-primary-high-emphasis);
      @extend .font-H2;
      font-weight: 600;
    }

    p {
      @extend .font-H4;
      font-weight: 600;
    }

    img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 500px;
      max-width: 530px;
    }

    button {
      @extend .font-H3;
      width: fit-content;
      background-color: var(--color-on-primary-high-emphasis);
      color: var(--color-primary);
      border-radius: 8px;
      padding: 10px 25px;
      outline: 0;
      border: 0;
      font-weight: 700;
    }

    @media (max-width: 1100px) {
      img {
        width: 470px;
        left: -10px;
      }
    }

    @media (max-width: 930px) {
      img {
        width: 430px;
        left: -30px;
      }
    }

    @media (max-width: 832px) {
      height: unset;

      img {
        position: unset;
        width: 100%;
        margin-top: 30px;
      }

      button {
        padding: 7px 20px;
        font-size: 15px;
      }
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
}
