@import "../../../../shared/style/fonts";

.our-app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin-top: 30px;

  .app-data {
    display: flex;
    width: 80%;
    margin-top: 20px;
    direction: rtl;
    justify-content: space-between;
    flex-wrap: wrap;

    .right {
      width: 49%;
      background-image: url("/assets/images/mobile_screens.png");
      height: 565px;
      background-position: center;
      background-size: cover;
    }

    .left {
      width: 49%;
      display: flex;
      justify-content: center;
      align-items: center;

      .content {
        display: flex;
        flex-direction: column;
        width: 70%;

        h2 {
          @extend .font-T2;
          color: var(--color-primary);
          font-weight: 700;
        }

        p {
          margin-top: 15px !important;
          @extend .font-H3;
          color: var(--color-on-surface-high-emphasis);
          font-weight: 600;
        }
      }

      .images {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 150px;
          height: 100px;
          margin-inline-end: 15px;
        }
      }
    }

    @media (max-width: 902px) {
      justify-content: center;

      .left,
      .right {
        width: 100%;
      }

      .left {
        margin-top: 15px;
      }
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    @media (max-width: 500px) {
      .left {
        .images {
          img {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }
}
