@import "../../../../shared/style/fonts";

.footer {
  @extend .font-H3;
  display: flex;
  padding: 0 30px;
  align-items: center;
  justify-content: space-between;
  direction: rtl;
  min-height: 70px;
  background: var(--color-primary);
  color: var(--color-surface-light);
  flex-wrap: wrap;

  @media (max-width: 851px) {
    justify-content: center;
  }

  p {
    margin: 0;
    margin-inline-end: 10px;
  }

  .social {
    display: flex;
    align-items: center;

    .icons {
      img {
        width: 34px;
        height: 34px;
        margin-inline-end: 50px;
      }
    }

    .apps {
      padding: 10px 0;
      div {
        &:last-of-type {
          display: flex;
          justify-content: center;
        }
      }
      img {
        margin-inline-end: 25px;
        width: 140px;
      }
    }
  }

  @media (max-width: 670px) {
    justify-content: center;
    align-items: center;
    padding: 10px 0;

    p {
      margin-bottom: 10px;
      text-align: center;
    }

    .apps img {
      width: 100px !important;
      margin-inline-end: 15px;
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    li {
      margin-inline-end: 15px;

      a {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
      }
    }

    @media (max-width: 600px) {
      justify-content: center;
    }
  }
}
