.discount-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 600px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;

  .discount-background-color {
    width: 100%;
    height: 100%;
    background-color: #3b0a80;
    position: absolute;
    top: 0;
  }

  .discount-background-image {
    width: 100%;
    height: 100%;
    background-image: url("/assets/images/discount-background.svg");
    position: absolute;
    background-repeat: no-repeat;
    top: 0;
  }

  .discount-content {
    position: absolute;
    top: 0;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .left {
      width: 49%;
      display: flex;
      flex-direction: column;
      padding: 2% 5% 2% 0;

      h1 {
        font-size: 45px;
        color: #fff;
        margin-bottom: 20px;
      }

      > div {
        display: flex;
        align-items: center;
        gap: 20px;

        p {
          margin: 0;
          color: #fff;
          font-size: 40px;
          font-weight: 600;

          &:last-of-type {
            font-size: 60px;
          }
        }
      }

      .discount-form {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: 100%;
        align-items: flex-start;

        input {
          border: 0;
          outline: 0;
          border-radius: 8px;
          background-color: #fff;
          margin-bottom: 10px;
          width: 450px;
          padding: 13px 10px;
          max-width: 100%;
          direction: ltr;
          text-align: right;
          box-sizing: border-box;
        }

        button {
          background-color: #fff;
          color: #3b0a80;
          padding: 10px 30px;
          border-radius: 10px;
          border: 0;
          font-weight: 600;
          font-size: 20px;
        }
      }
    }

    .right {
      position: relative;
      width: 49%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        &:first-of-type {
          width: 300px;
          height: 30%;
        }

        &:last-of-type {
          width: 600px;
          height: 70%;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .left {
      width: 100% !important;

      .discount-form {
        padding: 10px;
      }
    }

    .right {
      display: none !important;
    }
  }

  @media (max-width: 600px) {
    .left {
      h1 {
        font-size: 25px !important;
      }

      p {
        font-size: 20px !important;
      }
    }
  }
  .ant-input-affix-wrapper {
    width: 450px;
    max-width: 100%;
    border: 0;
    border-radius: 10px;
  }

  .ant-input-suffix {
    direction: ltr;
  }

  input:last-of-type {
    margin-bottom: 0 !important;
    padding: 10px !important;
  }
}
