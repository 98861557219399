@import "../../../../shared/style/fonts";

.header {
  display: flex;
  padding: 0 30px;
  align-items: center;
  justify-content: space-between;
  direction: rtl;
  min-height: 70px;
  background: var(--color-surface-light);
  box-shadow: 0px 0px 20px var(--color-shadow-primary);
  position: fixed;
  width: 100%;
  z-index: 1000;
  .logo {
    img {
      width: 140px;
    }
  }

  @media (max-width: 400px) {
    .logo {
      img {
        width: 130px;
      }
    }
  }

  .navbar,
  .navbar-nav {
    width: 100%;
  }

  .navbar-collapse {
    width: 100%;
    background-color: var(--color-surface-light);
    @media (max-width: 991px) {
      padding-bottom: 20px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .list {
      list-style: none;
      display: flex;
      align-items: center;
      margin: 0;
      margin-inline-start: 10px;
      padding: 0;

      li {
        margin-inline-end: 20px;
        color: var(--color-on-surface-high-emphasis);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 13px;
        font-weight: 600;

        a {
          color: var(--color-on-surface-high-emphasis);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          text-decoration: none;
          font-size: 13px;
          font-weight: 600;
        }

        @media (max-width: 1000px) {
          margin-inline-end: 20px;
        }

        &.registerButton {
          background: var(--color-primary);
          border-radius: 10px;
          width: 100px;
          height: 40px;
          cursor: pointer;
          a {
            color: var(--color-surface-light);
            cursor: pointer;
            text-decoration: none;
          }
        }

        &.my-account-button {
          img {
            margin-inline-end: 5px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .language {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        color: var(--color-on-surface-high-emphasis);
        font-size: 13px;
        margin-inline-end: 10px;
        font-weight: 600;
      }

      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  .navbar-toggler {
    padding: 3px 7px;
    span {
      width: 27px;
    }
  }

  .navbar-brand {
    margin: 0;
  }

  @media (max-width: 992px) {
    .content {
      flex-direction: column;
      align-items: flex-end !important;

      ul {
        flex-direction: column;
        padding: 0;
        align-items: flex-end !important;

        li {
          margin: 0 !important;
          margin-bottom: 15px !important;
          padding-inline-end: 20px;
        }
      }

      .language {
        margin-inline-end: 43px;
      }
    }
  }
}
