.view-doctor {
  width: 100%;
  justify-content: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 500px) {
    padding: 15px;
  }

  .vaccine-container {
    .vaccine-card {
      background: #fff !important;
      border: 0.25px solid #646d82;
      border-radius: 5px;
      margin-bottom: 20px;
      padding: 10px;
      display: flex;
      width: 100%;

      .left {
        flex-grow: 1;
        display: flex;
        align-items: center;

        img {
          width: 70px;
          margin-inline-end: 15px;
        }

        .vaccine-content {
          background-color: transparent;
          box-shadow: none;
          display: flex;
          flex-direction: column;

          span {
            color: #2e2e2e;
            font-weight: 500;

            &:first-of-type {
              margin-bottom: 15px;
            }

            &:last-of-type {
              color: var(--color-primary);
            }
          }
        }
      }

      .right {
        width: fit-content;
        flex-basis: unset;
        display: flex;
        align-items: flex-end;

        p {
          background-color: var(--color-primary);
          color: #fff;
          padding: 5px 20px;
          border-radius: 3px;
          font-size: 14px;
          cursor: pointer;
          margin-bottom: 0;
        }
      }
    }
  }
}
