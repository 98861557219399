@import "../../shared/style/fonts";

.offers-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #EBE9F7;

  .headers {
    background-color: #ad9fbf14;
    display: flex;
    padding: 20px 30px 20px 55px;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;

    input {
      background-color: var(--color-surface-light) !important;
      border-radius: 7px !important;
      height: 45px !important;
      width: 350px;
      flex: 1 1;

      @media (max-width: 500px) {
        width: 100% !important;
        flex: unset;
      }
    }

    .ant-select {
      flex: 1 1;
      @media (max-width: 500px) {
        width: 100%;
        flex: unset;
      }
    }

    .ant-select-selector {
      background-color: var(--color-surface-light) !important;
      border-radius: 7px !important;
      height: 45px !important;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .ant-select-arrow {
      right: unset !important;
      left: 22px !important;
    }

    .button {
      height: 40px;
      width: 80px;
      background: var(--color-primary);
      border-radius: 10px;
      outline: 0;
      border: 0;
      color: var(--color-surface-light);
      font-weight: 700;
      font-size: 12px;

      @media (max-width: 600px) {
        height: 30px;
        width: 70px;
      }
    }

    ::placeholder {
      @extend .font-H6;
    }

    .ant-select-selection-placeholder {
      @extend .font-H6;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    // gap: 40px;
    justify-content: space-between;
  }

  .element {
    background-color: var(--color-surface-light);
    box-shadow: 0px 0px 20px var(--color-shadow-primary);
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    width: 32%;
    padding-bottom: 20px;
    cursor: pointer;
    margin-bottom: 30px;

    @media (max-width: 950px) {
      width: 49%;
      margin-top: 20px;
    }

    @media (max-width: 750px) {
      width: 100%;
      margin-top: 20px;
    }

    .top-header {
      height: 170px;
      position: relative;
      margin-bottom: 5px;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 12px;

      .overlay-background {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(86, 86, 86, 0.4);
        display: none;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      > p {
        @extend .font-H5;
        position: absolute;
        top: -10px;
        left: 2px;
        z-index: 3;
        color: var(--color-surface-light);
        font-size: 18px;
        font-weight: 700;
      }

      > img {
        position: absolute;
        left: -19px;
        top: -30px;
        width: 70px;
        height: 70px;
        z-index: 2;
      }
    }

    .doctor-name {
      display: flex;
      align-items: center;
      direction: rtl;
      padding: 0 15px;
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: 100%;
      display: none;

      img {
        width: 45px;
        height: 45px;
        top: -30px;
        right: 5px;
      }

      > div {
        p {
          color: #a6abb5;
          margin: 0;
          color: #FFF;
          margin-inline-start: 10px;
          font-size: 14px;
  
          @media (max-width: 500px) {
            font-size: 9px;
          }
        }
      }
    }

    .specialty {
      color: var(--color-primary);
      padding: 0 15px;
      margin-top: 20px;
      font-size: 17px;
    }

    .operation {
      color: #a6abb5;
      @extend .font-H5;
      padding: 0 15px;
      margin-top: 7px;
    }

    .price {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: unset;
      margin-top: 10px;
      padding: 0 15px;

      p {
        margin: 0;
        font-size: 14px;

        span:first-of-type {
          color: #3A2874;
          text-decoration: line-through;
          margin-inline-end: 15px;
        }

        span:last-of-type {
          color: #18D563;
        }
      }

      .bottom-part {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;

        span {
          color: #3A2874;
        }

        button {
          color: #3A2874;
          border: 1px solid #3A2874;
          padding: 5px 20px;
          border-radius: 5px;
          font-weight: 600;
          outline: 0;
          background-color: #FFF;
          font-size: 15px;
        }
      }

    }

    &:hover {
      .overlay-background {
        display: block;
      }

      button {
        color: #FFF !important;
        background-color: #3A2874 !important;

      }

      .doctor-name {
        display: flex;
      }
    }
  }

  .background {
    padding: 30px;
    .background-image {
      width: 100%;
      height: 320px;
    }
  }

  .offers-cards {
    padding: 30px;

    h3 {
      color: #646d82;
      margin-bottom: 30px;
      font-weight: 600;
      font-size: 22px;
    }
  }

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      color: #646d82;
      margin-bottom: 30px;
      font-weight: 600;
    }
  }

  .more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 34px 0;

    button {
      @extend .font-H4;
      border: 0;
      outline: 0;
      color: var(--color-on-primary-high-emphasis);
      background-color: var(--color-primary);
      border-radius: 10px;
      padding: 10px 20px;
    }
  }
}
