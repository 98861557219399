@import "../../../../shared/style/fonts";

.filter-component {
  flex-basis: 21%;
  margin-inline-end: 20px;

  @media (max-width: 975px) {
    display: none;
    &.active {
      display: flex;
      flex-direction: column;
    }
  }

  .title {
    background: var(--color-primary);
    color: var(--color-surface-light);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    h3 {
      color: var(--color-surface-light);
      width: fit-content;
      @extend .font-H3;
      margin: 0;
    }
  }

  .ant-collapse {
    background: var(--color-surface-light);
    box-shadow: 0px 3px 60px #c4c4c433;
    margin-bottom: 5px;
  }

  .ant-collapse-content {
    padding-left: 25px;
  }

  .ant-collapse-item {
    border: 0;
  }

  .ant-collapse-header {
    @extend .font-H4;
    color: var(--color-primary) !important;
    font-weight: 600;
  }

  .anticon {
    position: absolute;
    left: 0;
    top: 15px;
    font-size: 15px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-primary) !important;
  }

  .checkbox {
    @extend .font-H5;
    margin-bottom: 10px;
    color: var(--color-primary-dark);
  }

  .get-all {
    @extend .font-H5;
    color: var(--color-primary);
    margin-top: 10px;
  }

  .ant-slider-track {
    background-color: #8a8296;
  }

  .ant-slider-handle {
    box-shadow: 0px 0px 6px var(--color-medium-dark) 33;
    border: 0.25px solid var(--color-on-surface-high-emphasis);
  }

  .ant-radio-checked .ant-radio-inner::after {
    background-color: var(--color-primary);
  }

  .ant-radio-wrapper {
    margin: 0;
    margin-bottom: 10px;
  }

  .ant-radio-checked .ant-radio-inner {
    border: 1px solid var(--color-primary);
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }

  .ant-collapse-arrow {
    margin-left: 12px;
  }

  .data {
    display: flex;
    flex-direction: column;
  }
}
