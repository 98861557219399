@import "../../../../shared/style/fonts";

.search-inputs {
  width: 100%;
  background-color: #ad9fbf14;
  padding: 30px 50px 30px 20px !important;

  .title {
    margin-bottom: 20px;
    h3 {
      @extend .font-H2;
      color: var(--color-primary) !important;
      font-weight: 600;
    }
  }

  .content-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > div {
      flex-basis: 15%;
      margin-inline-end: 15px;

      &.online-input {
        flex-basis: 25%;
      }

      @media (max-width: 800px) {
        flex-basis: 30% !important;
        margin-top: 10px;
      }
    }

    @media (max-width: 540px) {
      justify-content: space-between;
      > div {
        margin: 0;
        margin-top: 10px;
        flex-basis: 48% !important;
      }
    }
  }

  .ant-select-single {
    width: 100% !important;
  }

  .ant-select-selector {
    background-color: var(--color-surface-light) !important;
    border-radius: 7px !important;
    height: 35px !important;
    display: flex;
    align-items: center;
  }

  .ant-select-arrow {
    right: unset !important;
    left: 22px !important;
  }

  label {
    @extend .font-H4;
    color: var(--color-primary-dark);
    margin-bottom: 7px;
    display: block;
  }

  input {
    background-color: var(--color-surface-light) !important;
    border-radius: 7px !important;
    height: 35px !important;
  }

  .button {
    height: 40px;
    width: 80px;
    background: var(--color-primary);
    border-radius: 10px;
    outline: 0;
    border: 0;
    color: var(--color-surface-light);
    margin-top: 25px;
    font-weight: 700;
    font-size: 12px;

    @media (max-width: 600px) {
      height: 30px;
      width: 70px;
    }
  }

  ::placeholder {
    @extend .font-H6;
  }

  .ant-select-selection-placeholder {
    @extend .font-H6;
  }
}
