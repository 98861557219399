@import "../../../../shared/style/fonts";

.doctors-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;

  @media (max-width: 670px) {
    width: 100%;
  }

  .left,
  .right {
    flex-basis: 49%;

    > div {
      box-shadow: 0px 0px 40px var(--color-shadow-primary);
      margin: 0;
      padding: 15px;
    }

    @media (max-width: 915px) {
      flex-basis: 100%;
      margin-top: 20px;
    }
  }

  .left {
    > div {
      margin-bottom: 5px;
      border-radius: 10px;
    }
  }

  .doctor-header {
    @extend .font-H3;
    color: var(--color-on-surface-high-emphasis);
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    img {
      width: 20px;
      height: 20px;
      padding: 0;
      margin-inline-end: 10px;
    }

    p {
      width: fit-content;
      font-weight: 600;
      margin: 0;
    }
  }

  .info {
    @extend .font-H4;
    color: var(--color-on-surface-high-emphasis);
  }

  .specialties {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    span {
      background-color: var(--color-primary);
      color: var(--color-surface-light);
      @extend .font-H5;
      border-radius: 4px;
      margin-inline-end: 10px;
      margin-bottom: 10px;
      display: inline-block;
      text-align: center;
      padding: 5px 0;
      padding: 7px 12px;
    }
  }

  .booking-info {
    display: flex;
    justify-content: center;
    color: var(--color-surface-light);
    @extend .font-H3;
    background: var(--color-primary);
    justify-content: center;
    padding: 15px 0;
    border-radius: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    font-weight: bold;

    &.online {
      background: var(--color-surface-light);
      border-bottom: 1px solid #ddd;
      color: var(--color-primary);
      font-weight: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;

      &:last-of-type {
        flex-direction: column;
      }
    }

    &.data {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      img {
        margin-inline-end: 10px;
        width: 25px;
        height: 22px;
      }
      span {
        @extend .font-H4;
        color: var(--color-on-surface-high-emphasis);
      }
    }

    span {
      width: fit-content;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;

      p {
        margin: 0;
        color: var(--color-on-surface-high-emphasis);
      }
    }
  }

  .appointments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .header-book {
      display: flex;
      width: 70%;
      justify-content: center;
      background: var(--color-primary);
      color: var(--color-surface-light);
      align-items: center;
      margin-top: 10px;
      padding: 10px 0;
      border-radius: 4px;

      p {
        margin: 0;
      }
    }

    img {
      width: 30px;
      height: 30px;
      margin-inline-end: 20px;
      margin-inline-start: 20px;
      cursor: pointer;

      &:last-of-type {
        transform: rotate(180deg);
      }
    }

    .timing {
      width: 70%;
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0;
      justify-content: center;

      span {
        @extend .font-H5;
        background: var(--color-primary);
        color: var(--color-surface-light);
        padding: 10px 15px;
        margin-inline-end: 10px;
        margin-bottom: 10px;
        flex-grow: 1;
        text-align: center;
        flex-basis: 25%;
        cursor: pointer;

        &:hover,
        &.active {
          background-color: #b1b6c0;
        }

        &.app-offer {
          background-color: #0f8424;
        }
      }
    }

    .button {
      width: 70%;

      button {
        width: 100%;
        background-color: #cb0000;
        font-weight: bold;
        text-decoration: none;
        text-align: center;
        padding: 10px 0;
        border-radius: 8px;
        align-self: flex-end;
        justify-self: flex-end;
        border: 0;
        outline: 0;
        @extend .font-H4;
        text-decoration: none;
        color: var(--color-surface-light);
      }

      @media (max-width: 500px) {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          padding: 6px 0;
        }
      }
    }
  }

  .medical-center {
    display: flex;
    align-items: center;

    > div {
      margin-inline-start: 20px;
    }

    p {
      @extend .font-H4;
      color: var(--color-medium-dark);
      margin: 0;

      &:last-of-type {
        @extend .font-H5;
        color: var(--color-on-surface-high-emphasis);
        margin-top: 10px;
      }
    }
  }
}
