@import "../../../../shared/style/fonts";

.medical-info-container {
  width: 80%;
  //   min-height: calc(100% - 250px);

  .doctors-info {
    width: 100% !important;
  }

  .ant-tabs-tab {
    width: 150px;
    min-width: 100px;
    margin: 0;
    display: flex;
    justify-content: center;
    color: #9899a5;
    font-weight: 600;
  }

  .left {
    flex-basis: 100%;

    > div {
      box-shadow: 0px 0px 40px var(--color-shadow-primary);
      margin: 0;
      padding: 15px;
    }

    @media (max-width: 915px) {
      flex-basis: 100%;
      margin-top: 20px;
    }
  }

  .left {
    > div {
      margin-bottom: 5px;
      border-radius: 10px;
    }
  }

  .doctor-header {
    @extend .font-H3;
    color: var(--color-on-surface-high-emphasis);
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    img {
      width: 20px;
      height: 20px;
      padding: 0;
      margin-inline-end: 10px;
    }

    p {
      width: fit-content;
      font-weight: 600;
      margin: 0;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;

      p {
        margin: 0;
        color: var(--color-on-surface-high-emphasis);
      }
    }
  }

  .info {
    @extend .font-H4;
    color: var(--color-on-surface-high-emphasis);
  }

  .specialties {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    span {
      background-color: var(--color-primary);
      color: var(--color-surface-light);
      @extend .font-H5;
      border-radius: 4px;
      margin-inline-end: 10px;
      margin-bottom: 10px;
      display: inline-block;
      text-align: center;
      padding: 5px 0;
      padding: 7px 12px;
    }
  }
}
