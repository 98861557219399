.medical-history {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .medical-card {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 20px #0000000d;
    width: 32%;
    margin-bottom: 20px;
    border-radius: 7px;
    cursor: pointer;

    .medical-header {
      padding: 20px;
      background-color: rgba(59, 10, 128, 0.1);
      border-radius: 7px;
      padding: 15px;

      h3 {
        font-size: 17px;
        color: #000000;
        font-weight: 600;
      }

      p {
        color: #4d4d4d;
        font-size: 16px;
        margin: 0;
      }
    }

    .medical-data {
      padding: 5px 15px 15px;
      p {
        color: #000000;
        font-weight: 600;
      }

      span {
        color: #6d6d6d;
      }
    }
  }

  .lab-details-container {
    width: 100%;
    padding: 20px;

    .lab-header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      img {
        width: 30px;
        height: 30px;
        margin-inline-end: 20px;
        cursor: pointer;
      }

      p {
        margin: 0;
        font-size: 18px;
        color: #646d82;
      }
    }

    .lab-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 40px;

      .lab-image {
        width: 49%;
        display: flex;
        align-items: center;
        position: relative;

        > div:first-of-type {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          border: 1px solid #707070;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            width: 90%;
            height: 90%;
          }
        }

        .lab-name {
          margin-inline-start: 20px;
          display: flex;
          flex-direction: column;

          h3 {
            color: #000000;
            font-size: 20px;
          }

          p {
            color: #646d82;
            font-size: 17px;
          }
        }

        .lab-doctor {
          display: flex;
          align-items: center;
          position: absolute;
          bottom: -19px;
          right: 70px;

          > div:first-of-type {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid #707070;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin-inline-end: 10px;
          }

          img {
            width: 25px;
            height: 25px;
          }

          h3 {
            color: #000000;
            font-size: 15px;
            margin: 0;
          }

          p {
            color: #646d82;
            font-size: 14px;
            margin: 0;
          }
        }
      }

      .lab-address {
        padding: 20px;
        border-radius: 7px;
        background-color: #fbfbfb;

        > div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 20px;

          img {
            width: 25px;
            height: 25px;
            margin-inline-end: 15px;
          }

          p {
            margin: 0;
          }

          a {
            display: flex;
            align-items: center;
            text-decoration: none;

            p {
              margin: 0;
              color: var(--color-on-surface-high-emphasis);
            }
          }
        }
      }
    }

    .lab-report {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        margin-bottom: 15px;
        align-items: center;

        p {
          color: #222222;
          font-size: 17px;
          margin: 0;
          margin-inline-start: 10px;
        }
      }

      > p {
        color: #000000;
        font-size: 15px;
      }
    }

    .lab-result {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;

      .lab-analysis {
        width: 49%;
        display: flex;
        flex-direction: column;

        div:first-of-type {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          p {
            margin: 0;
            margin-inline-start: 10px;
            color: #222222;
            font-size: 17px;
          }
        }

        span {
          background-color: var(--color-primary);
          padding: 5px 10px;
          border-radius: 5px;
          color: #fff;
          font-size: 14px;
          margin-inline-end: 10px;
        }
      }

      .lab-medicine {
        width: 49%;
        display: flex;
        flex-direction: column;

        div:first-of-type {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          p {
            margin: 0;
            margin-inline-start: 10px;
            color: #222222;
            font-size: 17px;
          }
        }

        div:last-of-type {
          display: flex;
          flex-direction: column;

          span {
            font-size: 14px;
            color: var(--color-primary);
            margin-bottom: 10px;

            span {
              display: inline-block;
              color: #646d82;
              margin-inline-start: 10px;
            }
          }
        }
      }
    }
  }
}
