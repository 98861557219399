@import "../../../../shared/style/fonts";

.doctors-data {
  box-shadow: 0px 0px 40px var(--color-shadow-primary);
  margin: 0;
  margin-bottom: 10px;
  border-radius: 10px;
  width: 80%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 670px) {
    width: 100%;
  }

  .doctor-image {
    display: flex;
    flex-basis: 15%;
    margin-inline-end: 10px;

    img {
      width: 160px;
      height: 160px;
      border-radius: 50%;

      @media (max-width: 900px) {
        width: 140px;
        height: 140px;
      }
    }

    @media (max-width: 670px) {
      flex-basis: 100%;
      justify-content: center;
      align-items: center;

      img {
        width: 120px;
        height: 120px;
      }
    }
  }

  .doctor-name {
    display: flex;
    flex-basis: 40%;
    flex-direction: column;
    flex: 2;

    p {
      color: var(--color-on-surface-high-emphasis);
      @extend .font-H3;
      &:first-of-type {
        @extend .font-H2;
        color: var(--color-primary);
        font-weight: 600;
      }
    }

    @media (max-width: 670px) {
      flex-basis: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
    }
  }

  .doctor-rate {
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex-basis: 20%;
    flex: 1;

    @media (max-width: 900px) {
      flex-basis: 100%;
      margin-top: 15px;
    }

    @media (max-width: 670px) {
      flex-basis: 100%;
      justify-content: center;
      align-items: center;
    }

    .rate {
      display: flex;
      align-items: center;

      .ant-rate {
        width: fit-content;
      }

      &:last-of-type {
        display: flex;
        margin-top: 30px;
        flex-direction: column;
        align-items: start;
      }
    }

    .medical-logo {
      width: fit-content;
      @media (max-width: 670px) {
        margin-top: 10px;
      }

      p {
        @extend .font-H3;
        color: var(--color-medium-dark);

        &:last-of-type {
          @extend .font-H4;
          color: var(--color-on-surface-high-emphasis);
          margin-top: 10px;
        }
      }
    }
    img {
      width: 20px;
      height: 20px;
      padding: 0;
      margin-inline-end: 10px;
    }

    p {
      color: var(--color-on-surface-high-emphasis);
      @extend .font-H4;
      width: fit-content;
      margin: 0;
    }
  }
}
