@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700&display=swap");
@import "./shared/style/palette";

* {
  margin: 0;
  padding: 0;
  font-family: "Cairo", sans-serif;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .page-content {
    flex: 1;
    display: flex;
    text-align: right;
    margin-top: 70px;
    background-color: var(--color-surface-light);
  }
}
::-webkit-scrollbar {
  width: 8px;
  background-color: var(--color-surface-light);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
