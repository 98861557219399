@import "../../../../shared/style/fonts";

.our-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin-top: 30px;

  .service-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80%;

    @media (max-width: 600px) {
      width: 100%;
    }

    h4 {
      @extend .font-H2;
      color: var(--color-primary);
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
    }

    h2 {
      @extend .font-T3;
      color: var(--color-on-surface-high-emphasis);
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
    }
    span {
      width: 65px;
      padding: 3px 0;
      background: var(--color-primary);
      border-radius: 20px;
    }
  }

  .service-data {
    display: flex;
    width: 80%;
    margin-top: 20px;
    direction: rtl;
    justify-content: space-between;
    flex-wrap: wrap;

    .right {
      width: 49%;
      background-image: url("/assets/images/landing-page.png");
      height: 500px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .left {
      max-width: 49%;
      min-width: 36%;

      > div {
        display: flex;
        align-items: center;
        direction: rtl;
        margin-top: 40px;
      }

      img {
        width: 45px;
        height: 45px;
        margin-inline-end: 15px;
      }

      .content {
        display: flex;
        flex-direction: column;

        h3 {
          @extend .font-H2;
          color: var(--color-on-surface-high-emphasis);
          font-weight: 600;
        }

        p {
          @extend .font-H5;
          color: #02111d;
          line-height: 1.8;
        }
      }
    }

    @media (max-width: 902px) {
      justify-content: center;

      .left,
      .right {
        width: 100%;
        min-width: unset;
        max-width: unset;
      }
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    @media (max-width: 500px) {
      .left {
        min-width: unset;
        max-width: unset;
        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}
