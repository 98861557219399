@import "../../../../shared/style/fonts";

.search-doctor {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 860px) {
    justify-content: space-between;
    padding-bottom: 20px;
  }

  > div {
    display: flex;
    flex-direction: column;
    flex-basis: 15%;
    margin-inline-end: 20px;

    &.name {
      flex-basis: 25%;
    }

    @media (max-width: 1010px) {
      flex-basis: 20%;
    }

    @media (max-width: 860px) {
      flex-basis: 49%;
      margin: 0;
      margin-top: 20px;

      &.name {
        flex-basis: 49%;
      }
    }

    @media (max-width: 460px) {
      flex-basis: 100% !important;
    }
  }

  .ant-select-single {
    width: 100% !important;
  }

  .ant-select-selector {
    background-color: var(--color-primary-light) !important;
    border-radius: 7px !important;
    height: 35px !important;
    display: flex;
    align-items: center;
  }

  .ant-select-arrow {
    right: unset !important;
    left: 22px !important;
  }

  label {
    @extend .font-H4;
    color: var(--color-primary-dark);
    margin-bottom: 7px;
    display: block;
  }

  input {
    background-color: var(--color-primary-light) !important;
    border-radius: 7px !important;
    height: 35px !important;
  }

  .button {
    flex-basis: 10%;
    margin: 0;

    @media (max-width: 860px) {
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button {
      height: 35px;
      width: 80px;
      background: var(--color-primary);
      border-radius: 10px;
      outline: 0;
      border: 0;
      color: var(--color-surface-light);
      margin-top: 25px;
      font-weight: 700;
      font-size: 12px;
    }

    a {
      color: var(--color-surface-light);
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
