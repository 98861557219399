.handle-404 {
  display: flex;
  width: 100%;
  align-items: center;

  img {
    width: 100%;
    height: 300px;
  }
}
