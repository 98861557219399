@import "../../../../shared/style/fonts";

.profile-data {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  width: 100%;
  padding: 10px;

  @media (max-width: 500px) {
    padding: 15px;
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 70%;
    align-items: center;
    justify-content: center;

    @media (max-width: 850px) {
      width: 100%;
    }

    h3 {
      @extend .font-T4;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      color: var(--color-primary);
      margin-bottom: 20px;
    }

    .form {
      display: flex;
      flex-direction: column;
      width: 100%;

      .fields {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;

        .field {
          display: flex;
          flex-basis: 49%;
          -webkit-flex-direction: column;
          flex-direction: column;

          @media (max-width: 500px) {
            flex-basis: 100% !important;
          }

          &.password {
            position: relative;

            .display-password {
              position: absolute;
              left: 20px;
              top: 16px;
              color: var(--color-primary);
              font-size: 12px;
              font-weight: bold;
              cursor: pointer;
              z-index: 100;
            }
          }

          &.name {
            flex-basis: 100%;
          }

          .ant-form-item-label {
            width: fit-content;
          }

          .ant-form-item-control {
            min-width: 100%;
          }

          .ant-input-affix-wrapper {
            padding: 0;
            border: 0;
          }

          .ant-input-suffix {
            display: none;
          }

          .ant-input-affix-wrapper:focus,
          .ant-input-affix-wrapper-focused {
            box-shadow: none;
          }

          .ant-radio-group {
            height: 50px;
            display: flex;
            align-items: center;
          }

          .ant-radio-wrapper {
            margin-inline-end: 50px;
          }

          label {
            @extend .font-H3;
            color: var(--color-primary-dark);
            &::before {
              display: none;
            }
          }

          input,
          .ant-picker {
            width: 100%;
            padding: 8px;
            background: var(--color-primary-light);
            border: 0.25px solid var(--color-border-medium);
            border-radius: 12px;
            outline: none;
            font-size: 15px;
            color: var(--color-primary-dark);
            margin-bottom: 20px;
            direction: ltr;
            text-align: right;

            input {
              padding: 0;
              border: 0;
              margin: 0;
            }

            &:focus {
              background-color: var(--color-surface-light);
            }

            &::placeholder {
              @extend .font-H4;
              color: var(--color-placeholder);
            }
          }

          input {
            margin-bottom: 15px;
          }
        }
      }

      .ant-form-item {
        margin: 0;
      }

      button {
        @extend .font-H4;
        width: 140px;
        height: 45px;
        background: var(--color-primary);
        border-radius: 12px;
        color: var(--color-surface-light);
        outline: none;
        border: 0;
        font-weight: bold;
        margin: 10px 0 0;
      }
    }

    a {
      @extend .font-H3;
      color: var(--color-primary);
      cursor: pointer;
      text-decoration: none;
      width: fit-content;
      display: block;
      margin-bottom: 25px;

      span {
        color: var(--color-primary-dark);
        cursor: text;
        margin-top: 10px;
        display: inline-block;
      }
    }

    .change-text {
      color: var(--color-primary);
      font-weight: 600;
      margin: 10px 0;
      cursor: pointer;
    }
  }

  p {
    @extend .font-H6;
    color: var(--color-primary);
    margin: 0;
  }

  .ant-form-item-explain {
    color: var(--color-error);
    font-size: 10px;
  }

  .backdrop {
    z-index: 1000;
    color: var(--color-surface-light);
  }

  .ant-row {
    width: 100%;
  }

  .ant-form-item-label {
    display: flex;
    justify-content: flex-start;
  }
}

.change-password {
  .ant-form-item-label {
    text-align: right !important;
  }

  .ant-input-affix-wrapper {
    border-radius: 7px;
    padding: 10px;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  h3 {
    color: var(--color-primary);
    @extend .font-H2;
    margin: 30px 0 20px;
  }

  label {
    @extend .font-H4;
  }

  button {
    background-color: var(--color-primary);
    color: var(--color-surface-light);
    border-radius: 5px;
    border: 0;
    outline: 0;
    width: 120px;
    text-align: center;
    padding: 10px 0;
  }
}
