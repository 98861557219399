@import "../../../../shared/style/fonts";

.offers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin-top: 30px;

  .offers-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 25px;

    @media (max-width: 600px) {
      width: 100%;
    }

    h4 {
      @extend .font-H2;
      color: var(--color-primary);
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
    }

    h2 {
      @extend .font-T3;
      color: var(--color-on-surface-high-emphasis);
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
    }
    span {
      width: 65px;
      padding: 3px 0;
      background: var(--color-primary);
      border-radius: 20px;
    }

    p {
      @extend .font-H3;
      color: #9d9d9d;
      margin-top: 15px;
      margin-bottom: 10px;
      text-align: center;
      font-weight: 700;
    }

    a {
      @extend .font-H5;
      direction: rtl;
      color: #8871b3;
      text-decoration: none;
      font-weight: 600;

      img {
        margin-inline-start: 5px;
        width: 15px;
        height: 15px;
      }
    }
  }

  .offers-data {
    display: flex;
    width: 80%;
    direction: rtl;
    margin-top: 30px;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 600px) {
      width: 100%;
    }

    .element {
      background-color: var(--color-surface-light);
      box-shadow: 0px 0px 20px var(--color-shadow-primary);
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      width: 32%;
      padding-bottom: 20px;
      cursor: pointer;

      @media (max-width: 950px) {
        width: 49%;
        margin-top: 20px;
      }

      @media (max-width: 750px) {
        width: 100%;
        margin-top: 20px;
      }

      .top-header {
        height: 150px;
        position: relative;
        background-image: url("/assets/images/offers.png");
        margin-bottom: 5px;
        background-size: cover;
        background-repeat: no-repeat;

        p {
          @extend .font-H5;
          position: absolute;
          top: 0;
          left: 0;
          background: #cb0505;
          color: var(--color-surface-light);
          border-top-left-radius: 12px;
          border-bottom-right-radius: 12px;
          padding: 5px 12px;
        }
      }

      .doctor-name {
        display: flex;
        justify-content: space-between;
        direction: rtl;
        padding: 0 15px;
        position: relative;

        img {
          width: 45px;
          height: 45px;
          position: absolute;
          top: -30px;
          right: 5px;
        }

        > p {
          font-weight: 700;
          font-size: 10px;
          color: #a6abb5;
          margin: 0;
          margin-inline-start: 45px;

          @media (max-width: 500px) {
            font-size: 9px;
          }
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          p {
            font-size: 9px;
            color: #a6abb5;
            font-weight: 700;
            margin: 0;

            @media (max-width: 500px) {
              font-size: 7px;
            }
          }
        }
      }

      .specialty {
        color: var(--color-primary);
        @extend .font-H5;
        font-weight: 700;
        padding: 0 15px;
        margin-top: 20px;
      }

      .operation {
        color: #a6abb5;
        @extend .font-H5;
        font-weight: 700;
        padding: 0 15px;
        margin-top: 7px;
      }

      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        padding: 0 15px;

        p {
          margin: 0;
          span:first-of-type {
            color: #a6abb5;
            text-decoration: line-through;
            margin-inline-end: 5px;
            font-weight: 600;
            @extend .font-H6;
          }
          span:last-of-type {
            @extend .font-H5;
            font-weight: 700;
            color: var(--color-primary);
          }
        }

        button {
          color: var(--color-surface-light);
          background-color: var(--color-primary);
          padding: 7px 20px;
          border-radius: 10px;
          @extend .font-H5;
          font-weight: 600;
          border: 0;
          outline: 0;
        }
      }
    }
  }

  .anticon svg {
    width: 13px;
    height: 13px;
  }

  .ant-rate {
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      height: 100%;
      margin: 0;
    }
  }

  .ant-rate-star-second {
    display: flex;
  }
}
